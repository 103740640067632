import React, { useEffect, useState } from "react";
import { useDataGrid, EditButton, ShowButton, List } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";

import { supabaseClient } from "../../utility";
import { CreateButton } from "components/buttons/create";
import { TablePaginationActions } from "components/TablePaginationActions";

export const TenantsList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();

    const [rowData, setRowData] = useState<any[]>([]);
    const [isCounted, setIsCounted] = useState(false);

    const [docQueryStartDate, setDocQueryStartDate] = useState(
        dayjs().startOf("month")
    );
    const [docQueryEndDate, setDocQueryEndDate] = useState(
        dayjs().endOf("month")
    );

    const { dataGridProps } = useDataGrid({
        meta: {
            select: "id,Name",
        },
    });

    useEffect(() => {
        const getDocumentCounts = async () => {
            const new_rowdata: any[] = await Promise.all(
                dataGridProps?.rows?.map(async (item: any) => {
                    const { count } = await supabaseClient
                        .from("documents")
                        .select("id", { count: "exact", head: true })
                        .eq("tenant_id", item.id)
                        .gte(
                            "created_at",
                            String(docQueryStartDate.format("YYYY-MM-DD"))
                        )
                        .lt(
                            "created_at",
                            String(docQueryEndDate.format("YYYY-MM-DD"))
                        );
                    return { ...item, documents: count };
                })
            );
            if (new_rowdata.length > 0) {
                setIsCounted(true);
                console.log("NEW ROWDATA", new_rowdata);
                setRowData(new_rowdata);
            }
        };

        if (isCounted === false) {
            getDocumentCounts();
        }
    }, [
        dataGridProps,
        docQueryStartDate,
        docQueryEndDate,
        setIsCounted,
        setRowData,
        isCounted,
    ]);

    const columnVisible = {
        id: false,
    };

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("Tenants.fields.id"),
                type: "number",
                minWidth: 50,
                show: false,
            },
            {
                field: "Name",
                flex: 1,
                headerName: translate("Tenants.fields.Name"),
                minWidth: 200,
            },
            {
                field: "documents",
                flex: 1,
                headerName: translate("Tenants.fields.Documents"),
                minWidth: 200,
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row.id} />
                            <ShowButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
                disableColumnMenu: true,
            },
        ],
        [translate]
    );

    const DateRangePicker = () => {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Beginning of"
                    views={["month", "year", "day"]}
                    maxDate={docQueryEndDate}
                    value={docQueryStartDate}
                    onChange={(newValue) => {
                        setDocQueryStartDate(dayjs(newValue));
                        setIsCounted(false);
                    }}
                    sx={{ "& .MuiInputBase-input": { p: "10px" } }}
                />
                <DatePicker
                    label="To end of"
                    views={["month", "year", "day"]}
                    value={docQueryEndDate}
                    onChange={(newValue) => {
                        setDocQueryEndDate(dayjs(newValue));
                        setIsCounted(false);
                    }}
                    sx={{ "& .MuiInputBase-input": { p: "10px" } }}
                />
            </LocalizationProvider>
        );
    };

    return (
        <List
            headerButtons={[
                <DateRangePicker key={"dateRangePicker"} />,
                <CreateButton
                    key={"createButton"}
                    sx={{ padding: ".25rem 1.5rem", minwidth: "7.5rem", textTransform: "capitalize" }}
                >
                    Add Tenant
                </CreateButton>,
            ]}
            wrapperProps={{
                className: "tenants",
            }}
        >
            <DataGrid
                {...dataGridProps}
                rows={rowData}
                columns={columns}
                columnVisibilityModel={columnVisible}
                autoHeight
                slotProps={{
                    pagination: {
                        ActionsComponent: TablePaginationActions
                    }
                }}
            />
        </List>
    );
};
